<template>
  <div
    class="pointer d-flex flex-row align-content-center align-items-center"
    @click="$emit('on:remove-link-click')"
  >
    <span class="fa fa-minus text-danger-light"/>
    <span
      class="ml-2 light-danger-col-title"
    >
      {{ $t(textLink) }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    textLink: {
      type: String,
      default: ''
    }
  }
}
</script>
